.btn {
  text-transform: uppercase;
  border-radius: 999px;
  padding: 7.5px 50px;
  &.secondary {
    color: $color-primary;
    background: $color-white;
    border: solid 1px $color-primary;
  }
  &.primary {
    color: $color-white;
    background: $color-primary;
    border: solid 1px $color-primary;
  }
}
