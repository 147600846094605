.CategoriePage {
  .productcategorielijst {
    .product {
      .product--content {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        width: 100%;
        height: 125px;
        padding: 20px 30px;
        left: 0;
        bottom: 0;
        * {
          color: $color-white;
          text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.CategoriePageAlternative {
  .productcategorielijst {
    .product {
      .product--content {
        position: absolute;
        width: 100%;
        height: 125px;
        padding: 20px 30px;
        left: 0;
        bottom: 0;
        * {
          color: $color-white;
          text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 20px 30px;
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
  }
  .productcategorielijst--layered {
    background: #eee;
    padding: 15px;
    border-radius: 5px;
    ul {
      list-style: none;
      padding-left: 0;
    }
    .btn.secondary {
      background: #e65051;
      border-color: #e65051;
      color: $color-white;
      width: 100%;
      i {
        padding-right: 15px;
      }
    }
    .checkbox {
      margin-right: 10px;
      &:checked + label {
        font-weight: bold;
      }
      + label {
        vertical-align: -1px;
      }
    }
    .checkbox {
      position: relative;
      &::after {
        width: 13px;
        height: 13px;
        position: absolute;
        top: 0;
        left: 0;
        background: #eee;
        border: solid 1px #8b8b8f;
        content: ' ';
      }
      &:checked::after {
        content: '\f00c';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 11px;
        text-align: center;
        line-height: 10px;
        color: $color-primary;
      }
    }
  }
  .contactformulier {
    [type='text'],
    [type='email'],
    textarea {
      color: $color-black;
      background: #eee;
      width: 100%;
      padding: 10px 15px;
      margin-bottom: 15px;
      border-radius: 10px;
    }
    .btn-toolbar {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
  }
}
