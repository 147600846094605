.security {
  height: 100vh;
  background: $color-primary;
  .security--form {
    padding: 20px;
    background: $color-white;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    h1 {
      a {
        color: $color-primary;
        &:hover {
          text-decoration: none;
        }
      }
    }
    input.text {
      border: solid 1px $color-gray;
      padding: 10px 15px;
      width: 100%;
      margin-bottom: 25px;
      border-radius: 999px;
    }
    input.checkbox {
      padding-bottom: 20px;
    }
    .security--back {
      color: $color-black;
      margin-top: 25px;
      display: block;
    }
    .action {
      text-transform: uppercase;
      border-radius: 999px;
      padding: 7.5px 50px;
      color: $color-white;
      background: $color-primary;
      border: solid 1px $color-primary;
    }
    #ForgotPassword {
      padding-left: 15px;
      margin-bottom: 0;
      line-height: 38px;
      a {
        color: $color-black;
      }
    }
  }
}
