.nieuwsartikel {
  p {
    margin-bottom: 5px;
  }
  a {
    color: $color-primary;
    &:hover {
      text-decoration: none;
    }
  }
  strong:first-child {
    color: $color-primary;
  }
}
.categorie {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: ' ';
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
  }
  .categorie--title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .h1 {
      color: $color-white;
      font-weight: 300;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
    }
  }
  .categorie--button {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    a {
      margin-top: 60px;
    }
  }
}
.randomproductlijst {
  .product {
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: ' ';
      background: rgba(0, 0, 0, 0.4);
      left: 0;
      top: 0;
    }
    .product--title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    a:hover {
      text-decoration: none;
    }
  }
}
.product {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  .product--title {
    .h1 {
      color: $color-white;
      font-weight: 300;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
    }
  }
  .product--button {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    a {
      margin-top: 60px;
    }
  }
}
