.header {
  position: sticky;
  top: 0;
  height: 0;
  width: 100%;
  z-index: 999;
  .header--background {
    background: rgba(255, 255, 255, 0);
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0;
    transition: background ease-in-out 0.2s;
  }
  .header--top {
    background: $color-white;
    .header--top-social {
      color: $color-gray;
      font-size: 18px;
      &:hover {
        text-decoration: none;
        color: $color-black;
      }
    }
    .header--top-phone,
    .header--top-email {
      color: $color-black;
      i {
        color: $color-primary;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .header--navigation {
    ul {
      margin-bottom: 0;
      li {
        border-left: solid 1px $color-white;
        &:first-child {
          border-left: 0;
        }
        @media only screen and (max-width: 1550px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
        @media only screen and (max-width: 1300px) {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
      }
    }
    a {
      font-size: 18px;
      color: $color-white;
      &:hover {
        text-decoration: none;
        border-bottom: solid 2px $color-primary;
        padding-bottom: 5px;
      }
      &.current {
        border-bottom: solid 2px $color-primary;
        padding-bottom: 5px;
      }
      &.header--top-phone,
      &.header--top-email,
      &.header--menu {
        font-size: 28px;
        color: $color-white;
        border-bottom: 0;
        padding-bottom: 0;
      }
      &.header--menu.btnclose i::before {
        content: '';
      }
    }
  }
  &.alt-color {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    ul {
      li {
        border-left: solid 1px $color-black;
        &:first-child {
          border-left: 0;
        }
      }
    }
    .header--background {
      background: $color-white;
    }
    .header--navigation {
      a {
        color: $color-black;
        &.header--top-phone,
        &.header--top-email,
        &.header--menu {
          color: $color-gray;
        }
      }
    }
  }
}
