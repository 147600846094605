.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: Oswald;
  color: $color-primary;
  font-weight: 400;
}
.h1, h1 {
  font-size: 38px;
}
.h2, h2 {
  font-size: 36px;
}
.h3, h3 {
  font-size: 34px;
}
.h4, h4 {
  font-size: 32px;
}
.h5, h5 {
  font-size: 30px;
}
.h6, h6 {
  font-size: 28px;
}
