.content {
  .content--gallerij {
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .swiper-slide {
      height: 450px;
      background-size: cover;
      background-position: center;
    }
    .swiper-button-next {
      top: initial;
      right: 10px;
      bottom: 10px;
      left: auto;
      &::after {
        content: '\f054';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $color-white;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
      }
    }
    .swiper-button-prev {
      top: initial;
      right: 60px;
      bottom: 10px;
      left: auto;
      &::after {
        content: '\f053';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $color-white;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.tags {
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
  a {
    color: $color-black;
  }
}
.gerelateerdeproductlijst {
  .product {
    .product--title {
      position: absolute;
      bottom: 15px;
      left: 15px;
    }
  }
}
.offerteformulier {
  [type='text'],
  [type='email'],
  textarea {
    color: $color-black;
    background: #eee;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  .btn-toolbar {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}
