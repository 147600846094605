@charset "UTF-8";
@import url("../../node_modules/destyle.css/destyle.css");
@import url("../../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("../../node_modules/swiper/swiper-bundle.min.css");
@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Bold.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Orienta;
  src: url("../webfonts/Orienta-Regular.ttf");
  font-display: swap;
}

#cookie-bar {
  background: #fff;
  height: auto;
  line-height: 24px;
  color: #363636;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 0 0 5px 0 rgba(54, 54, 54, 0.15);
  -moz-box-shadow: 0 0 5px 0 rgba(54, 54, 54, 0.15);
  box-shadow: 0 0 5px 0 rgba(54, 54, 54, 0.15);
}

#cookie-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

#cookie-bar.fixed.bottom {
  bottom: 0;
  top: auto;
  z-index: 9999;
}

#cookie-bar p {
  margin: 0;
  padding: 0;
}

#cookie-bar a {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-decoration: none;
  padding: 7.5px 15px;
  margin-left: 8px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

#cookie-bar .cb-enable {
  background: #070;
}

#cookie-bar .cb-enable:hover {
  background: #090;
}

#cookie-bar .cb-disable {
  background: #900;
}

#cookie-bar .cb-disable:hover {
  background: #b00;
}

#cookie-bar .cb-policy {
  background: #b00;
}

#cookie-bar .cb-policy:hover {
  background: #05d;
}

body {
  font-family: Orienta;
  font-size: 14px;
}

ul, ol {
  list-style: disc;
  padding-left: 15px;
  text-align: left;
}

.btn {
  text-transform: uppercase;
  border-radius: 999px;
  padding: 7.5px 50px;
}

.btn.secondary {
  color: #090;
  background: #fff;
  border: solid 1px #090;
}

.btn.primary {
  color: #fff;
  background: #090;
  border: solid 1px #090;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: Oswald;
  color: #090;
  font-weight: 400;
}

.h1, h1 {
  font-size: 38px;
}

.h2, h2 {
  font-size: 36px;
}

.h3, h3 {
  font-size: 34px;
}

.h4, h4 {
  font-size: 32px;
}

.h5, h5 {
  font-size: 30px;
}

.h6, h6 {
  font-size: 28px;
}

.security {
  height: 100vh;
  background: #090;
}

.security .security--form {
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.security .security--form h1 a {
  color: #090;
}

.security .security--form h1 a:hover {
  text-decoration: none;
}

.security .security--form input.text {
  border: solid 1px #666;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 999px;
}

.security .security--form input.checkbox {
  padding-bottom: 20px;
}

.security .security--form .security--back {
  color: #333;
  margin-top: 25px;
  display: block;
}

.security .security--form .action {
  text-transform: uppercase;
  border-radius: 999px;
  padding: 7.5px 50px;
  color: #fff;
  background: #090;
  border: solid 1px #090;
}

.security .security--form #ForgotPassword {
  padding-left: 15px;
  margin-bottom: 0;
  line-height: 38px;
}

.security .security--form #ForgotPassword a {
  color: #333;
}

.header {
  position: sticky;
  top: 0;
  height: 0;
  width: 100%;
  z-index: 999;
}

.header .header--background {
  background: rgba(255, 255, 255, 0);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0;
  transition: background ease-in-out 0.2s;
}

.header .header--top {
  background: #fff;
}

.header .header--top .header--top-social {
  color: #666;
  font-size: 18px;
}

.header .header--top .header--top-social:hover {
  text-decoration: none;
  color: #333;
}

.header .header--top .header--top-phone,
.header .header--top .header--top-email {
  color: #333;
}

.header .header--top .header--top-phone i,
.header .header--top .header--top-email i {
  color: #090;
}

.header .header--top .header--top-phone:hover,
.header .header--top .header--top-email:hover {
  text-decoration: none;
}

.header .header--navigation ul {
  margin-bottom: 0;
}

.header .header--navigation ul li {
  border-left: solid 1px #fff;
}

.header .header--navigation ul li:first-child {
  border-left: 0;
}

@media only screen and (max-width: 1550px) {
  .header .header--navigation ul li {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .header .header--navigation ul li {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.header .header--navigation a {
  font-size: 18px;
  color: #fff;
}

.header .header--navigation a:hover {
  text-decoration: none;
  border-bottom: solid 2px #090;
  padding-bottom: 5px;
}

.header .header--navigation a.current {
  border-bottom: solid 2px #090;
  padding-bottom: 5px;
}

.header .header--navigation a.header--top-phone, .header .header--navigation a.header--top-email, .header .header--navigation a.header--menu {
  font-size: 28px;
  color: #fff;
  border-bottom: 0;
  padding-bottom: 0;
}

.header .header--navigation a.header--menu.btnclose i::before {
  content: '';
}

.header.alt-color {
  background: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.header.alt-color ul li {
  border-left: solid 1px #333;
}

.header.alt-color ul li:first-child {
  border-left: 0;
}

.header.alt-color .header--background {
  background: #fff;
}

.header.alt-color .header--navigation a {
  color: #333;
}

.header.alt-color .header--navigation a.header--top-phone, .header.alt-color .header--navigation a.header--top-email, .header.alt-color .header--navigation a.header--menu {
  color: #666;
}

#menu {
  position: fixed;
  z-index: 98;
  right: 0;
  top: 0;
  width: 350px;
  height: 100%;
  padding: 12.5px 30px;
}

#menu a {
  color: #333;
  font-size: 18px;
}

#menu a:hover {
  text-decoration: none;
}

#panel {
  position: relative;
  z-index: 99;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.slideout-open #panel::before {
  content: ' ';
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.breadcrumbs {
  color: #666;
}

.breadcrumbs a {
  color: #666;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.pagination .num.current {
  background: #090;
  color: #fff;
  padding: 10px;
  border-radius: 999px;
  width: 41px;
  height: 41px;
  text-align: center;
  font-size: 18px;
  line-height: 20.5px;
  margin-right: 5px;
  margin-left: 5px;
}

.pagination .num a {
  color: #666;
  background: #eee;
  padding: 10px;
  border-radius: 999px;
  width: 41px;
  height: 41px;
  text-align: center;
  font-size: 18px;
  line-height: 20.5px;
  margin-right: 5px;
  margin-left: 5px;
  display: inline-block;
}

.pagination .num a:hover {
  text-decoration: none;
}

.pagination .next.btn.primary {
  padding: 7.5px;
  width: 41px;
  margin-left: 5px;
}

.pagination .prev.btn.primary {
  padding: 7.5px;
  width: 41px;
  margin-right: 5px;
}

.banner {
  height: 75vh;
}

.banner .banner--gallerij {
  height: 100%;
}

.banner .banner--gallerij .bannerij--gallerij-slide {
  background-size: cover;
  background-position: center;
}

.banner .banner--gallerij .bannerij--gallerij-slide::before {
  content: ' ';
  background-color: black;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner .banner--gallerij .banner--gallerij-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  transform: translate(-50%, -50%);
  color: #fff;
}

.banner .banner--gallerij .banner--gallerij-title {
  font-size: 25px;
}

.banner .banner--gallerij .banner--gallerij-subtitle {
  font-size: 28px;
  line-height: 30px;
  font-family: Oswald;
  font-weight: 500;
}

.banner--static {
  height: 35vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.banner--static::before {
  content: ' ';
  background-color: #333;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.usp {
  background: #f9f9f9;
}

.usp .usp-text {
  color: #666;
  margin-bottom: 0;
}

.usp .usp-text i {
  color: #090;
}

.footerbanner {
  position: relative;
  min-height: 250px;
  background-position: center;
  background-size: cover;
}

.footerbanner .h1,
.footerbanner .h6 {
  color: #fff;
  font-weight: 300;
}

.footerbanner::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: ' ';
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
}

.footer {
  background: #333;
  color: #fff;
}

.footer strong {
  font-size: 18px;
  font-weight: 400;
}

.footer a {
  color: #fff;
}

.footer ul {
  margin-bottom: 0;
}

.footer ul li {
  line-height: 26px;
}

.footer .footer--copyright {
  background: #fff;
  color: #090;
}

.footer .footer--copyright p {
  margin-bottom: 0;
  font-size: 16px;
}

.nieuwsartikel p {
  margin-bottom: 5px;
}

.nieuwsartikel a {
  color: #090;
}

.nieuwsartikel a:hover {
  text-decoration: none;
}

.nieuwsartikel strong:first-child {
  color: #090;
}

.categorie {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.categorie::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: ' ';
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
}

.categorie .categorie--title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.categorie .categorie--title .h1 {
  color: #fff;
  font-weight: 300;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
}

.categorie .categorie--button {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.categorie .categorie--button a {
  margin-top: 60px;
}

.randomproductlijst .product::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: ' ';
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
}

.randomproductlijst .product .product--title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.randomproductlijst .product a:hover {
  text-decoration: none;
}

.product {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.product .product--title .h1 {
  color: #fff;
  font-weight: 300;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
}

.product .product--button {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.product .product--button a {
  margin-top: 60px;
}

.artikel {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.artikel::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: ' ';
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
}

.artikel .artikel--content {
  position: absolute;
  width: 100%;
  padding: 20px;
  left: 0;
  bottom: 0;
}

.artikel .artikel--content * {
  color: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.artikel .artikel--content p {
  margin-bottom: 5px;
}

.artikel .artikel--content p.h6 {
  font-weight: 100;
}

.artikel .artikel--content p:last-child {
  margin-bottom: 0;
}

.CategoriePage .productcategorielijst .product .product--content {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 125px;
  padding: 20px 30px;
  left: 0;
  bottom: 0;
}

.CategoriePage .productcategorielijst .product .product--content * {
  color: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.CategoriePage .productcategorielijst .product .product--content a:hover {
  text-decoration: none;
}

.CategoriePageAlternative .productcategorielijst .product .product--content {
  position: absolute;
  width: 100%;
  height: 125px;
  padding: 20px 30px;
  left: 0;
  bottom: 0;
}

.CategoriePageAlternative .productcategorielijst .product .product--content * {
  color: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 30px;
}

.CategoriePageAlternative .productcategorielijst .product .product--content a:hover {
  text-decoration: none;
}

.CategoriePageAlternative .productcategorielijst--layered {
  background: #eee;
  padding: 15px;
  border-radius: 5px;
}

.CategoriePageAlternative .productcategorielijst--layered ul {
  list-style: none;
  padding-left: 0;
}

.CategoriePageAlternative .productcategorielijst--layered .btn.secondary {
  background: #e65051;
  border-color: #e65051;
  color: #fff;
  width: 100%;
}

.CategoriePageAlternative .productcategorielijst--layered .btn.secondary i {
  padding-right: 15px;
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox {
  margin-right: 10px;
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox:checked + label {
  font-weight: bold;
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox + label {
  vertical-align: -1px;
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox {
  position: relative;
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox::after {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 0;
  background: #eee;
  border: solid 1px #8b8b8f;
  content: ' ';
}

.CategoriePageAlternative .productcategorielijst--layered .checkbox:checked::after {
  content: '\f00c';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 11px;
  text-align: center;
  line-height: 10px;
  color: #090;
}

.CategoriePageAlternative .contactformulier [type='text'],
.CategoriePageAlternative .contactformulier [type='email'],
.CategoriePageAlternative .contactformulier textarea {
  color: #333;
  background: #eee;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.CategoriePageAlternative .contactformulier .btn-toolbar {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.contactformulier [type='text'],
.contactformulier [type='email'],
.contactformulier textarea {
  color: #333;
  background: #eee;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.contactformulier .btn-toolbar {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.TagPage .productcategorielijst .product .product--content {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 125px;
  padding: 20px 30px;
  left: 0;
  bottom: 0;
}

.TagPage .productcategorielijst .product .product--content * {
  color: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.TagPage .productcategorielijst .product .product--content a:hover {
  text-decoration: none;
}

.content .content--gallerij {
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.content .content--gallerij .swiper-slide {
  height: 450px;
  background-size: cover;
  background-position: center;
}

.content .content--gallerij .swiper-button-next {
  top: initial;
  right: 10px;
  bottom: 10px;
  left: auto;
}

.content .content--gallerij .swiper-button-next::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fff;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.content .content--gallerij .swiper-button-prev {
  top: initial;
  right: 60px;
  bottom: 10px;
  left: auto;
}

.content .content--gallerij .swiper-button-prev::after {
  content: '\f053';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fff;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.tags {
  margin-bottom: 1rem;
}

.tags p {
  margin-bottom: 0;
}

.tags a {
  color: #333;
}

.gerelateerdeproductlijst .product .product--title {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.offerteformulier [type='text'],
.offerteformulier [type='email'],
.offerteformulier textarea {
  color: #333;
  background: #eee;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.offerteformulier .btn-toolbar {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
