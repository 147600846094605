.artikel {
  position: relative;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: ' ';
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
  }
  .artikel--content {
    position: absolute;
    width: 100%;
    padding: 20px;
    left: 0;
    bottom: 0;
    * {
      color: $color-white;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    }
    p {
      margin-bottom: 5px;
      &.h6 {
        font-weight: 100;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
