.contactformulier {
  [type='text'],
  [type='email'],
  textarea {
    color: $color-black;
    background: #eee;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  .btn-toolbar {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}
