#menu {
  position: fixed;
  z-index: 98;
  right: 0;
  top: 0;
  width: 350px;
  height: 100%;
  padding: 12.5px 30px;
  a {
    color: $color-black;
    font-size: 18px;
    &:hover {
      text-decoration: none;
    }
  }
}
#panel {
  position: relative;
  z-index: 99;
  background: $color-white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.slideout-open #panel::before {
  content: ' ';
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}
