.pagination {
  .num {
    &.current {
      background: $color-primary;
      color: $color-white;
      padding: 10px;
      border-radius: 999px;
      width: 41px;
      height: 41px;
      text-align: center;
      font-size: 18px;
      line-height: 20.5px;
      margin-right: 5px;
      margin-left: 5px;
    }
    a {
      color: $color-gray;
      background: #eee;
      padding: 10px;
      border-radius: 999px;
      width: 41px;
      height: 41px;
      text-align: center;
      font-size: 18px;
      line-height: 20.5px;
      margin-right: 5px;
      margin-left: 5px;
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .next.btn.primary {
    padding: 7.5px;
    width: 41px;
    margin-left: 5px;
  }
  .prev.btn.primary {
    padding: 7.5px;
    width: 41px;
    margin-right: 5px;
  }
}
