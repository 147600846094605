.usp {
  background: $color-lightgray;
  .usp-text {
    color: $color-gray;
    margin-bottom: 0;
    i {
      color: $color-primary;
    }
  }
}
