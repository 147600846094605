.banner {
  height: 75vh;
  .banner--gallerij {
    height: 100%;
    .bannerij--gallerij-slide {
      background-size: cover;
      background-position: center;
      &::before {
        content: ' ';
        background-color: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .banner--gallerij-content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80vw;
      transform: translate(-50%, -50%);
      color: $color-white;
    }
    .banner--gallerij-title {
      font-size: 25px;
    }
    .banner--gallerij-subtitle {
      font-size: 28px;
      line-height: 30px;
      font-family: Oswald;
      font-weight: 500;
    }
  }
}
.banner--static {
  height: 35vh;
  background-size: cover;
  background-position: center;
  position: relative;
  &::before {
    content: ' ';
    background-color: $color-black;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
