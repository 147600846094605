.footerbanner {
  position: relative;
  min-height: 250px;
  background-position: center;
  background-size: cover;
  .h1,
  .h6 {
    color: $color-white;
    font-weight: 300;
  }
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: ' ';
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
  }
}
.footer {
  background: $color-black;
  color: $color-white;
  strong {
    font-size: 18px;
    font-weight: 400;
  }
  a {
    color: $color-white;
  }
  ul {
    margin-bottom: 0;
    li {
      line-height: 26px;
    }
  }
  .footer--copyright {
    background: $color-white;
    color: $color-primary;
    p {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}
