.TagPage {
  .productcategorielijst {
    .product {
      .product--content {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        width: 100%;
        height: 125px;
        padding: 20px 30px;
        left: 0;
        bottom: 0;
        * {
          color: $color-white;
          text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
  }
}
